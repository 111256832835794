<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap py-5>
      <v-flex xs12 text-left>
      <span class="itemHeading">Package Rates</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-4>
      <v-layout wrap pb-10>
        <v-flex xs12> </v-flex>
      </v-layout>
      <v-flex
        xs12
        py-4
        hidden-sm-and-down
        v-for="(item, i) in pricing"
        :key="i"
      >
        <v-card height="60px" color="#68D389">
          <v-layout wrap justify-center py-5>
            <v-flex text-center  xs6>
              <h4 style="color: #ffffff; font-family: poppinssemibold">
                Package Rate
                <span>
                  <router-link :to="'/editPackage/' + item._id + '/' + program._id">
                    <v-icon
                      color="#ffffff"
                      style="font-size: 22px; font-family: poppinsbold"
                    >
                      mdi-circle-edit-outline
                    </v-icon>
                  </router-link>
                </span>
              </h4>
            </v-flex>
          
            <v-flex xs5 text-right>
              <span style="color: #ffffff; font-family: poppinssemibold">
                {{ item.fromDate.slice(0, 10) }} To
                {{ item.toDate.slice(0, 10) }}</span
              >
            </v-flex>
            <v-flex xs1>
              <v-dialog v-model="item.delete" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ffffff" v-on="on" v-bind="attrs"
                    >mdi-delete</v-icon
                  >
                </template>
                <v-card>
                  <v-card-title
                    >Are you sure you want to delete this Package?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="item.delete = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deletePackage(item)"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-card>
        <v-layout justify-start pt-5>
          <v-flex>
            <span style="font-family: poppinsRegular">Package Rate</span>
          </v-flex>
          <v-flex text-left>
            <span style="font-family: poppinsRegular">Indian</span>
          </v-flex>
          <v-flex text-left>
            <span style="font-family: poppinsRegular">Foreigner</span>
          </v-flex>
          <v-flex text-left>
            <span style="font-family: poppinsRegular">Children</span>
          </v-flex>
          <v-flex text-left>
            <span style="font-family: poppinsRegular"> Foreign Children</span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12>
            <v-card outlined>
              <v-layout wrap justify-start py-4 px-4 v-if="item">
                <v-flex>
                  <span style="font-family: poppinsmedium; color: #9b9090">
                    Week Days
                  </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular"> {{ item.indian }} INR </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular"> {{ item.foreigner }} INR </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular"> {{ item.children }} INR </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular"> {{ item.foreignStudent }} INR </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 pt-1>
            <v-card outlined>
              <v-layout wrap justify-start py-4 px-4 v-if="item.holidays">
                <v-flex>
                  <span style="font-family: poppinsmedium; color: #9b9090">
                    Weekends And Holidays
                  </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular"> {{ item.indian }} INR </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular">
                    {{ item.holidays.foreigner }} INR
                  </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular">
                    {{ item.holidays.children }} INR
                  </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular">
                    {{ item.holidays.foreignStudent }} INR
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 pt-1>
            <v-card outlined>
              <v-layout wrap justify-start py-4 px-4 v-if="item.isExtraPerHeadAvailable">
                <v-flex >
                  <span style="font-family: poppinsmedium; color: #9b9090">
                    Extra Per head
                  </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular"> {{ item.indian }} INR </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular">
                    {{ item.extraperhead.foreigner }} INR
                  </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular">
                    {{ item.extraperhead.children }} INR
                  </span>
                </v-flex>
                <v-flex text-left>
                  <span style="font-family: poppinsregular">
                    {{ item.extraperhead.foreignStudent }} INR
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-layout wrap justify-start pt-4>
            <v-flex xs12 text-left v-if="item.packageFacility.length > 0">
              <span style="font-family: poppinsregular">Package Facility</span>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex pa-2 v-for="pf in item.packageFacility" :key="pf">
                  <v-card flat color="#F2F2F2">
                    <v-layout wrap justify-center>
                      <v-flex xs12 pa-2 px-4 text-center>
                        <span style="font-family: poppinsregular">{{
                          pf
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
      <v-layout wrap justify-center pt-4 v-if="pricing.length > 0">
        <v-flex xs12 hidden-md-and-up v-for="(item, i) in pricing" :key="i">
          <v-card height="100px" color="#68D389">
            <v-layout wrap justify-center py-5>
              <v-flex md3 xs12>
                <h4 style="color: #ffffff; font-family: poppinssemibold">
                  Package Rate
                  <span>
                    <router-link :to="'/editPackage/' + item._id + '/' + program._id">
                      <v-icon
                        color="#ffffff"
                        style="font-size: 22px; font-family: poppinsbold"
                      >
                        mdi-circle-edit-outline
                      </v-icon>
                    </router-link>
                  </span>
                </h4>
              </v-flex>
              <v-flex xs12 md8 xs7>
                <span style="color: #ffffff; font-family: poppinssemibold">
                  {{ item.fromDate.slice(0, 10) }} to
                  {{ item.toDate.slice(0, 10) }}</span
                >
              </v-flex>
              <v-flex xs1>
              <v-dialog v-model="item.delete" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ffffff" v-on="on" v-bind="attrs"
                    >mdi-delete</v-icon
                  >
                </template>
                <v-card>
                  <v-card-title
                    >Are you sure you want to delete this Package?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="item.delete = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deletePackage(item)"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
            </v-layout>
          </v-card>
          <v-layout wrap justify-center pt-2>
            <v-flex xs12>
              <v-flex xs12>
                <span style="font-family: poppinsregular">Indian</span>
              </v-flex>
              <v-card outlined v-if="item">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Week Days
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.indian }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.holidays"> 
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Weekends And Holidays
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.holidays.indian }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.isExtraPerHeadAvailable">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Extra Per head
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.extraperhead.indian }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-2>
            <v-flex xs12>
              <v-flex xs12>
                <span style="font-family: poppinsregular">Foreigner</span>
              </v-flex>
              <v-card outlined v-if="item">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Week Days
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.foreigner }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.holidays">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Weekends And Holidays
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.holidays.foreigner }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.isExtraPerHeadAvailable">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Extra Per head
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.extraperhead.foreigner }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-2>
            <v-flex xs12>
              <v-flex xs12>
                <span style="font-family: poppinsregular">Children</span>
              </v-flex>
              <v-card outlined v-if="item">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Week Days
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.children }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.holidays">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Weekends And Holidays
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.holidays.children }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.isExtraPerHeadAvailable">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Extra Per head
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.extraperhead.children }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-layout wrap justify-start pt-4>
              <v-flex xs12 text-left v-if="item.packageFacility.length > 0">
                <span style="font-family: poppinsregular"
                  >Package Facility</span
                >
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex pa-2 v-for="pf in item.packageFacility" :key="pf">
                    <v-card flat color="#F2F2F2">
                      <v-layout wrap justify-center>
                        <v-flex xs12 pa-2 px-4 text-center>
                          <span style="font-family: poppinsregular">{{
                            pf
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
          <v-layout wrap justify-center pt-2>
            <v-flex xs12>
              <v-flex xs12>
                <span style="font-family: poppinsregular">Foreign Children</span>
              </v-flex>
              <v-card outlined v-if="item">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Week Days
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.foreignStudent }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.holidays">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Weekends And Holidays
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.holidays.foreignStudent }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pt-1>
              <v-card outlined v-if="item.isExtraPerHeadAvailable">
                <v-layout wrap justify-start py-4 px-4>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular; color: #606060">
                      Extra Per head
                    </span>
                  </v-flex>
                  <v-flex xs6>
                    <span style="font-family: poppinsregular">
                      {{ item.extraperhead.foreignStudent }} INR
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-layout wrap justify-start pt-4>
              <v-flex xs12 text-left v-if="item.packageFacility.length > 0">
                <span style="font-family: poppinsregular"
                  >Package Facility</span
                >
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex pa-2 v-for="pf in item.packageFacility" :key="pf">
                    <v-card flat color="#F2F2F2">
                      <v-layout wrap justify-center>
                        <v-flex xs12 pa-2 px-4 text-center>
                          <span style="font-family: poppinsregular">{{
                            pf
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      program: {},
      pricing: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/get",
        params: {
          id: this.id,
          //   date: new Date(),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.program = response.data.data;
          if (response.data.package) this.pricing = response.data.package;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deletePackage(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/program/rate/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>